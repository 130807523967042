@use "../util" as *;
.footer {
  &__copyright {
    text-align: center;
    font-size: rem(13);
    color: var(--text-color);
  }
  &__link {
    color: var(--text-color2);
    &:hover {
      color: var(--brightred);
    }
  }
}
