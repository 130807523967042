html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  font-family: var(--font-inter);
  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--text-color);
}
