//Toggle https://codepen.io/SaraSoueidan/pen/jpBbrq
@use "../util" as *;
.toggle {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint(medium) {
    justify-content: end;
  }

  &__wrapper {
    font-size: rem(9);
    display: flex;
    vertical-align: middle;
    width: 7em;
    height: 3.5em;
    border-radius: 3.5em;
    position: relative;
  }

  input[type="radio"] {
    display: inline-block;
    width: 50%;
    opacity: 0;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &[id="dark"]:checked ~ .toggle__button {
      left: 10%;
    }
    &[id="dark"]:checked ~ __background {
      background: linear-gradient(
        225deg,
        var(--toggle-bg-start) 0%,
        var(--toggle-bg-end) 98%
      );
    }
  }

  &__background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 3.5em;
    background: var(--toggle-bg);
    transition: all 0.1s ease-out;
  }

  &__button {
    display: block;
    position: absolute;
    right: 0;
    left: 50%;
    top: 50%;
    transform: translate(0, -50%);
    right: 100%;
    width: rem(27);
    height: rem(27);
    border-radius: 50%;
    background-color: var(--toggle-button);
    transition: all 0.1s ease-out;
    z-index: 2;
  }

  .label {
    font-size: rem(18);
    font-weight: 700;
    margin: 0 0.5em 0;
    cursor: pointer;
    display: none;
    &--show {
      display: block;
    }
  }
}
