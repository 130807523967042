:root {
  --font-inter: "Inter", sans-serif;
}

:root {
  --limegreen: hsl(163, 72%, 41%);
  --brightred: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-middle: hsl(5, 77%, 71%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --toggle-bg-light: hsl(230, 22%, 74%);
  --toggle-bg-start: hsl(210, 78%, 56%);
  --toggle-bg-end: hsl(146, 68%, 55%);
  --toggle-light: hsl(230, 19%, 60%);
  --toggle-button-light: hsl(228, 46%, 96%);
  --dark-bg: hsl(230, 17%, 14%);
  --dark-top-bg: hsl(232, 19%, 15%);
  --dark-card: hsl(228, 28%, 20%);
  --dark-card-hover: hsl(228, 25%, 27%);
  --dark-text1: hsl(228, 34%, 66%);
  --dark-text2: hsl(0, 0%, 100%);
  --light-bg: hsl(0, 0%, 100%);
  --light-top-bg: hsl(225, 100%, 98%);
  --light-card: hsl(227, 47%, 96%);
  --light-card-hover: hsl(228, 33%, 91%);
  --light-text1: hsl(230, 12%, 44%);
  --light-text2: hsl(230, 17%, 14%);
  --background: var(--light-bg);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text1);
  --card-bg: var(--light-card);
  --card-hover: var(--light-card-hover);
  --toggle: var(--toggle-light);
  --toggle-bg: var(--toggle-bg-light);
  --toggle-button: var(--toggle-button-light);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: var(--dark-bg);
    --text-color: var(--dark-text2);
    --text-color2: var(--dark-text1);
    --card-bg: var(--dark-card);
    --card-hover: var(--dark-card-hover);
    --toggle: var(--light-bg);
    --toggle-bg: linear-gradient(
      225deg,
      var(--toggle-bg-end) 0%,
      var(--toggle-bg-start) 98.02%
    );
    --toggle-button: var(--dark-bg);
  }
}
body.light {
  --background: var(--light-bg);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text1);
  --card-bg: var(--light-card);
  --card-hover: var(--light-card-hover);
  --toggle: var(--toggle-light);
  --toggle-bg: var(--toggle-bg-light);
  --toggle-button: var(--toggle-button-light);
}

body.dark {
  --background: var(--dark-bg);
  --text-color: var(--dark-text2);
  --text-color2: var(--dark-text1);
  --card-bg: var(--dark-card);
  --card-hover: var(--dark-card-hover);
  --toggle: var(--light-bg);
  --toggle-bg: linear-gradient(
    225deg,
    var(--toggle-bg-end) 0%,
    var(--toggle-bg-start) 98.02%
  );
  --toggle-button: var(--dark-bg);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  font-family: var(--font-inter);
  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
  line-height: 1.1;
}

h1 {
  font-style: 1.5rem;
}
@media (min-width: 71.875em) {
  h1 {
    font-style: 1.75rem;
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  max-width: 69.375rem;
  margin: 0 auto 2.875rem;
  padding: 0 1.5625rem;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
  }
}

.header {
  margin-top: 2.25rem;
}
@media (min-width: 40em) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header__subtitle {
  color: var(--text-color2);
  font-weight: 700;
  font-size: 0.875rem;
}
.header hr {
  border-color: var(--text-color2);
  margin: 1.25rem 0 0.9375rem 0;
  width: 100%;
}
@media (min-width: 40em) {
  .header hr {
    display: none;
  }
}

.toggle {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 40em) {
  .toggle {
    justify-content: end;
  }
}
.toggle__wrapper {
  font-size: 0.5625rem;
  display: flex;
  vertical-align: middle;
  width: 7em;
  height: 3.5em;
  border-radius: 3.5em;
  position: relative;
}
.toggle input[type=radio] {
  display: inline-block;
  width: 50%;
  opacity: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.toggle input[type=radio][id=dark]:checked ~ .toggle__button {
  left: 10%;
}
.toggle input[type=radio][id=dark]:checked ~ __background {
  background: linear-gradient(225deg, var(--toggle-bg-start) 0%, var(--toggle-bg-end) 98%);
}
.toggle__background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 3.5em;
  background: var(--toggle-bg);
  transition: all 0.1s ease-out;
}
.toggle__button {
  display: block;
  position: absolute;
  right: 0;
  left: 50%;
  top: 50%;
  transform: translate(0, -50%);
  right: 100%;
  width: 1.6875rem;
  height: 1.6875rem;
  border-radius: 50%;
  background-color: var(--toggle-button);
  transition: all 0.1s ease-out;
  z-index: 2;
}
.toggle .label {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 0.5em 0;
  cursor: pointer;
  display: none;
}
.toggle .label--show {
  display: block;
}

.cards__title {
  margin: inherit 0 1.5625rem 0;
}
.cards__wrapper {
  display: grid;
  gap: 1.5625rem;
  grid-template-columns: 1fr;
}
@media (min-width: 40em) {
  .cards__wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 52.5em) {
  .cards__wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.card {
  width: 100%;
  background-color: var(--card-bg);
  color: var(--text-color2);
  height: 11.875rem;
  padding: 1.5625rem;
  transition: background 150ms ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.card--big {
  border-radius: 0 0 0.375rem 0.375rem;
  border-top: 0.34375rem solid transparent;
  position: relative;
  /*The background extends to the outside edge of the padding. No background is drawn beneath the border.*/
  background-clip: padding-box;
}
.card::before {
  content: "";
  position: absolute;
  top: -0.25rem;
  right: 0;
  bottom: 90%;
  left: 0;
  z-index: -1;
  border-radius: 0.375rem; /* inherit container box's radius */
}
.card--facebook:before {
  background: var(--facebook);
}
.card--instgram:before {
  background: linear-gradient(225deg, var(--instagram-end), var(--instagram-middle) 50.91%, var(--instagram-start) 100%);
}
.card--twitter:before {
  background: var(--twitter);
}
.card--youtbe:before {
  background: var(--youtube);
}
.card__platform-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4375rem;
}
.card__icon {
  max-width: 1.25rem;
  min-width: 1.25rem;
}
.card__username {
  font-size: 0.8125rem;
  margin: 0;
}
.card__username a {
  color: var(--text-color2);
}
.card__username a:hover {
  color: var(--text-color);
}
.card__count-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.card__count {
  color: var(--text-color);
  display: block;
  text-align: center;
  font-weight: bold;
}
.card__count--small {
  font-size: 1.5625rem;
}
.card__count--big {
  font-size: 3.125rem;
}
.card__label {
  letter-spacing: 0.3125rem;
  text-transform: uppercase;
  font-size: 0.75rem;
}
.card__change {
  font-size: 0.75rem;
  margin: 0;
}
.card__change::before {
  content: "";
  width: 0.5rem;
  height: 0.25rem;
  display: inline-block;
  background-repeat: no-repeat;
}
.card__change--up {
  color: var(--limegreen);
}
.card__change--up::before {
  background-image: url(../../../images/icon-up.svg);
}
.card__change--down {
  color: var(--brightred);
}
.card__change--down::before {
  background-image: url(../../../images/icon-down.svg);
}
.card--small {
  height: 7.5rem;
  border-radius: 0.375rem;
}
.card__top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.card__meta {
  font-size: 0.9375rem;
  margin-top: 0;
}
.card__bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}
.card:hover {
  background-color: var(--card-hover);
  cursor: pointer;
}

.footer__copyright {
  text-align: center;
  font-size: 0.8125rem;
  color: var(--text-color);
}
.footer__link {
  color: var(--text-color2);
}
.footer__link:hover {
  color: var(--brightred);
}