@use "../util" as *;

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
  line-height: 1.1;
}

h1 {
  font-style: rem(24);
  @include breakpoint(large) {
    font-style: rem(28);
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
}
